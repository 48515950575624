import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)


Vue.use(VueMatomo, {
  // Configure your Matomo server and site by providing:
  host: 'https://matomo.tjhyatt.com/',
  siteId: 2,
})

window._paq.push(['trackPageView']); // To track a page view

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
