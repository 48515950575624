<template>
  <div style="height: 100%;">
    <div class="route-filter">
      <div class="route-filter__input-wrapper">
        <input type="text" placeholder="Route Number" list="route-list" v-model="routeId" @keyup.esc="routeId = ''" @keyup.enter="filterRouteId()">
        <datalist id="route-list" @change="filterRouteId()">
          <option v-for="routeId in routeList" :key="routeId" :value="routeId">{{ routeId }}</option>
        </datalist>
        <button v-if="routeId" class="route-filter__clear" @click="setRoutefilter(null)">
          <img src="@/assets/icons/times.svg" alt="Clear Icon" style="height: 18px; margin: auto;">
        </button>
      </div>
      <button class="route-filter__search" @click="filterRouteId()">
        <img src="@/assets/icons/search.svg" alt="Search Icon" style="height: 18px; margin: auto;">
      </button>
    </div>
    <div 
      class="clock"
      :style="`background: conic-gradient(from 0deg, #0063f2 0%, #0063f2 ${refreshTimer / .3}%, white 0%);`"
    ></div>
    
    <MglMap
      class="map"
      :class="{ 'marker-transition' : !isMapZooming }"
      :accessToken="accessToken"
      :zoom="12"
      :mapStyle="mapStyle"
      :attributionControl="false"
      @movestart="isMapZooming = true"
      @moveend="isMapZooming = false"
      @zoomstart="isMapZooming = true"
      @zoomend="isMapZooming = false"
      @load="onMapLoaded"
    >
      <MglNavigationControl position="bottom-right"/>
      <MglGeolocateControl position="bottom-right"/>
      <MglMarker 
        v-for="entity in filteredPositions"
        :key="entity.id"
        :coordinates="[entity.vehicle.position.longitude, entity.vehicle.position.latitude]"
        @click="setRoutefilter(entity)"
      >
        <div slot="marker" style="z-index: 1000000;">
          <img
            class="marker"
            :class="[
              {'marker--active': entity.vehicle.trip.tripId === activeTripId},
              {'marker--inactive': activeTripId && entity.vehicle.trip.tripId !== activeTripId},
            ]"
            :src="require('../assets/icons/marker.svg')"
            :style="`transform: rotate(${entity.vehicle.position.bearing}deg)`"
          />
        </div>
      </MglMarker>
      <MglMarker 
        v-for="stop in tripStops"
        :key="stop.id"
        :coordinates="[stop.long, stop.lat]"
        @click="getStop(stop)"
      >
        <div slot="marker">
          <img
            class="stop"
            :class="[
              {'stop--active': stop.id === activeStopId},
              {'stop--inactive': activeStopId && stop.id !== activeStopId},
            ]"
            :src="require('../assets/icons/stop.svg')"
          />
        </div>
      </MglMarker>
      <MglGeojsonLayer
        type="line"
        :sourceId="'sourceId'"
        :layerId="'layerId'"
        :layer="{
          type: 'line',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#0063f2',
            'line-width': 5
          }
        }"
        :source="{
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: tripShapes
            }
          }
        }"
      />
    </MglMap>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  MglMap, 
  MglMarker,
  MglNavigationControl,
  MglGeolocateControl,
  MglGeojsonLayer
} from 'vue-mapbox'

export default {
  components: {
    MglMap,
    MglMarker,
    MglNavigationControl,
    MglGeolocateControl,
    MglGeojsonLayer
  },

  data () {
    return {
      accessToken: 'pk.eyJ1IjoidGpoeWF0dCIsImEiOiJjazZoZDU5ZTAwZjkzM2RscWZpMWlsMXdyIn0.IhOoqdBxeMLzMQnHAZCArw',
      mapbox: null,
      mapStyle: 'mapbox://styles/tjhyatt/cksvl0n9l1oec18peszeci0la',
      // mapStyle: 'mapbox://styles/tjhyatt/cldb6b6nv001b01qt07rf1x7n',
      refreshTimer: 1,
      routeId: '',
      isMapZooming: false,
    }
  },

  watch: {
    $route: {
      handler (newRoute, oldRoute) {
        const routeId = newRoute.params.routeId

        if (routeId) {
          this.$store.commit('SET_ROUTE_ID', routeId)
          this.routeId = routeId
        } else {
          this.$store.dispatch('RESET_FILTER')
          this.routeId = null
        }
      },
      immediate: true
      
    },

    refreshTimer (timer) {
      if (timer === 30) {
        this.$store.dispatch('GET_VEHICLE_POSITIONS')
      }
    }
  },

  computed: {
    ...mapGetters([
      'activeStopId',
      'activeTripId',
      'filteredPositions',
      'tripShapes',
      'tripStops',
      'routeList'
    ])
  },

  async mounted () {
    await this.$store.dispatch('GET_VEHICLE_POSITIONS')

    setInterval(() => {
      this.refreshTimer++

      if (this.refreshTimer > 30) {
        this.refreshTimer = -1
      }
    }, 1000)
  },

  methods: {
    filterRouteId () {
      this.$router.push(`/${this.routeId}`)
    },

    getStop (stop) {
      this.$store.commit('SET_ACTIVE_STOP_ID', stop.id)
      this.$store.commit('SET_STOP', stop)
    },

    async setRoutefilter (entity) {
      if (entity) {
        const routeId = entity.vehicle.trip.routeId
        const tripId = entity.vehicle.trip.tripId

        this.$store.commit('SET_ACTIVE_TRIP_ID', tripId)

        const trip = await this.$store.dispatch('GET_TRIP', tripId)
        this.$store.dispatch('GET_STOPS', tripId)

        if (trip) {
          const shapeId = trip.shape_id
          this.$store.dispatch('GET_SHAPES', shapeId)
        }

        this.routeId = routeId
        this.filterRouteId()
      } else {
        this.$router.push('/')
      }
    },

    onMapLoaded (e) {
      e.map.setCenter([138.59863, -34.92866])
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  display: flex;
  height: 100%;
  width: 100%;
}

.clock {
  background: conic-gradient(from 0deg, #0063f2 0%, #0063f2 0%, white 0%);
  border-radius: 50%;
  // box-shadow: 1px 1px 6px rgba(0, 0, 0, .5);
  height: 24px;
  left: 10px;
  position: absolute;
  top: 17px;
  transition: all 1s linear;
  width: 24px;
  z-index: 2000000;
}

.route-filter {
  align-items: center;
  top: 10px;
  display: flex;
  left: 44px;
  margin: 0 auto;
  position: absolute;
  right: 10px;
  z-index: 2000000;

  &__input-wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  &__clear {
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0 40px 40px 0;
    box-shadow: none;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background: #e6e6e6;
    }
  }

  input {
    border: none;
    border-radius: 40px;
    margin: 0 1px 0 auto;
    padding: 8px 32px 6px 10px;
    width: 100%;

    @media screen and (min-width: 540px) {
      width: auto;
    }
  }

  &__search {
    border: none;
    border-radius: 40px;
    display: flex;
    height: 36px;
    margin: 0 0 0 4px;
    width: 36px;
  }
}

.marker {
  cursor: pointer;
  height: 32px;
  width: 32px;

  @media screen and (min-width: 540px) {
    height: 28px;
    width: 28px;
  }

  &--inactive {
    display: none;
  }
}

.stop {
  cursor: pointer;
  height: 22px;
  width: 22px;

  @media screen and (min-width: 540px) {
    height: 18px;
    width: 18px;
  }

  &--inactive {
    opacity: .35;
  }
}

.marker-transition {
  transition: 300ms all;

  /deep/ .mapboxgl-marker {
    transition: 300ms all;
  }
}

/deep/ .mapboxgl-user-location-dot::before {
  left: 0;
}

/deep/ .mapboxgl-ctrl-bottom-right {
  z-index: 2000000;
}
</style>