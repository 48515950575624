<template>
  <div class="home" style="height: 100%;">
    <MetroMap2 />
    <InfoPanel />
  </div>
</template>

<script>
// @ is an alias to /src
import InfoPanel from '@/components/InfoPanel'
import MetroMap2 from '@/components/MetroMap2.vue'

export default {
  name: 'Home',
  components: {
    InfoPanel,
    MetroMap2
  }
}
</script>
