<template>
  <div class="">
    <div v-if="activeTripId && activeTrip.route_id" class="panel panel--trip">
      <div class="panel__close" @click="clearTrip">
        <img src="@/assets/icons/times.svg" alt="Clear Icon" style="height: 18px; margin: auto;">
      </div>
      <div class="panel__header">
        <span style="font-weight: 700;">Trip Info</span> <span style="font-size: 12px; opacity: .8;">(ID: {{ activeTrip.trip_id }})</span>
      </div>
      <div>{{ activeTrip.trip_headsign }} - {{ activeTrip.route_id }}</div>
    </div>
    <div v-if="activeTripId && activeStop.id" class="panel panel--stop">
      <div class="panel__close" @click="clearStop">
        <img src="@/assets/icons/times.svg" alt="Clear Icon" style="height: 18px; margin: auto;">
      </div>
      <div class="panel__header">
        <span style="font-weight: 700;">Stop Info</span> <span style="font-size: 12px; opacity: .8;">(ID: {{ activeStop.id }})</span>
      </div>
      <div>Arrival Time: {{ activeStop.arrival_time }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'activeStop',
      'activeTrip',
      'activeTripId',
      'filteredPositions',
      'tripShapes',
      'tripStops',
    ])
  },

  methods: {
    clearStop () {
      this.$store.commit('SET_ACTIVE_STOP_ID', '')
      this.$store.commit('SET_STOP', {})
    },

    clearTrip () {
      this.clearStop()
      this.$store.commit('SET_ACTIVE_TRIP_ID', '')
      this.$store.commit('SET_TRIP', {})
      this.$store.commit('SET_STOPS', [])
      this.$store.commit('SET_SHAPES', [])
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background: #fff;
  border-radius: 5px;
  padding: 10px 0;
  position: fixed;
  right: 10px;
  width: 270px;
  z-index: 3000000;

  &--trip {
    top: 56px;
  }

  &--stop {
    top: 130px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  &__header {
    margin: 0 0 5px;
  }
}
</style>