import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeStopId: '',
    activeTripId: '',
    filterRouteId: '',
    route: {},
    shapes: [],
    stop: {},
    stops: [],
    trip: {},
    vehiclePositions: []
  },

  getters: {
    activeStop: state => {
      return state.stop
    },

    activeStopId: state => {
      return state.activeStopId
    },

    activeTrip: state => {
      return state.trip
    },

    activeTripId: state => {
      return state.activeTripId
    },

    filteredPositions: state => {
      if (state.filterRouteId) {
        return state.vehiclePositions.filter(entity => {
          if (entity.vehicle.trip.routeId === state.filterRouteId) {
            return entity
          }
        })
      } else {
        return state.vehiclePositions.filter(entity => {
          if (
            entity.vehicle.position.latitude !== 0 &&
            entity.vehicle.position.longitude !== 0
          ) {
            return entity
          }
        })
      }
    },

    tripShapes: state => {
      return state.shapes.map(shape => {
        return [shape.shape_pt_lon, shape.shape_pt_lat]
      })
    },

    tripStops: state => {
      return state.stops.map(stop => {
        return {
          id: stop.stop_id,
          arrival_time: stop.arrival_time,
          departure_time: stop.departure_time,
          lat: stop.stop.stop_lat,
          long: stop.stop.stop_lon,
        }
      })
    },

    routeList: state => {
      let list = state.vehiclePositions.map(position => {
        return position.vehicle.trip.routeId
      })

      return [...new Set(list)]
    }
  },

  mutations: {
    SET_ACTIVE_STOP_ID (state, stopId) {
      state.activeStopId = stopId
    },

    SET_ACTIVE_TRIP_ID (state, tripId) {
      state.activeTripId = tripId
    },

    SET_ROUTE (state, route) {
      state.route = route
    },
    
    SET_ROUTE_ID (state, newRouteId) {
      state.filterRouteId = newRouteId
    },
    
    SET_TRIP (state, trip) {
      state.trip = trip
    },
    
    SET_SHAPES (state, shapes) {
      state.shapes = shapes
    },
    
    SET_STOP (state, stop) {
      state.stop = stop
    },

    SET_STOPS (state, stops) {
      state.stops = stops
    },

    SET_VEHICLE_POSITIONS (state, newPositions) {
      state.vehiclePositions = newPositions
    },
  },

  actions: {
    GET_ROUTE ({commit}, routeId) {
      const options = {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': '*'
      }

      axios.get(process.env.VUE_APP_API_HOST + routeId.toUpperCase(), options)
        .then(function (response) {
          if (response.data.route[0]) {
            commit('SET_ROUTE', response.data.route[0])
          } else {
            commit('SET_ROUTE', {})
          }
        })
    },

    async GET_TRIP ({commit}, tripId) {
      return new Promise((resolve, reject) => {
        const options = {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*'
        }

        axios.get(process.env.VUE_APP_API_HOST + '/trip/' + tripId.toUpperCase(), options)
          .then(function (response) {
            console.log('res - trip', response)
            
            if (response.data.trip) {
              commit('SET_TRIP', response.data.trip)
            } else {
              commit('SET_TRIP', {})
            }

            resolve(response.data.trip)
          })
      })
    },

    GET_SHAPES ({commit}, shapeId) {
      return new Promise((resolve, reject) => {

        const options = {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*'
        }

        axios.get(process.env.VUE_APP_API_HOST + '/shape/' + shapeId.toUpperCase(), options)
          .then(function (response) {
            if (response.data.shapes) {
              commit('SET_SHAPES', response.data.shapes)
            } else {
              commit('SET_SHAPES', [])
            }

            resolve(response.data.shapes)
          })
      })
    },

    GET_STOPS ({commit}, tripId) {
      return new Promise((resolve, reject) => {

        const options = {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*'
        }

        axios.get(process.env.VUE_APP_API_HOST + '/stops/' + tripId.toUpperCase(), options)
          .then(function (response) {
            if (response.data.stops) {
              commit('SET_STOPS', response.data.stops)
            } else {
              commit('SET_STOPS', [])
            }

            resolve(response.data.stopTimes)
          })
      })
    },

    GET_VEHICLE_POSITIONS ({commit}) {
      const options = {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': '*'
      }

      axios.get(process.env.VUE_APP_API_HOST + '/vehicle-positions', options)
      // axios.get('https://metro.urgentpurple.com/vehicle-positions', options)
        .then(function (response) {
          // handle success
    
          const entities = response.data.feed.entity
          commit('SET_VEHICLE_POSITIONS', entities)
        })
        .catch(function (error) {
          // handle error
          console.log('errro')
          
          console.log(error)
        })
    },

    RESET_FILTER ({commit}) {
      commit('SET_ACTIVE_STOP_ID', '')
      commit('SET_ACTIVE_TRIP_ID', '')
      commit('SET_ROUTE', {})
      commit('SET_ROUTE_ID', '')
      commit('SET_SHAPES', [])
      commit('SET_TRIP', {})
      commit('SET_STOP', {})
      commit('SET_STOPS', [])
    }
  },

  modules: {
  }
})
